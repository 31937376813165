<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Slider Area  -->
    <div class="slider-wrapper">
      <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image history-bg"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title" style="color:#fff;">History</h2>
              <p>
                  (주)데이터엔지니어스랩의 수행실적을 안내드립니다.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->      
    </div>

    <!-- Start About Area  -->
    <div class="about-area ptb--120 bg_color--1">
      <div class="about-wrapper">
        <div class="container">
          <template>
            <v-row class="row--35">
              <v-col lg="6" md="6" cols="12" style="padding-bottom:20px;">
                <div class="inner">
                  <div class="section-title">
                    <span class="subtitle">Year of</span>
                    <h2 class="heading-title">2024</h2>
                  </div>
                  <div class="mt--20">
                    <ul class="history-area">
                    <li>롯데칠성음료(주) RGM 대쉬보드 구축 2024.09 ~ 2025.03</li>
                    <li>롯데칠성음료 데이터레이크 및 POS 시스템 아웃소싱 운영 2024.07 ~ 2025.06</li>
                    <li>CJ제일제당 수요예측모델 고도화 2024.06 ~ 2024.10 </li>
                    <li>롯데이노베이트 2024년 스마트리온 시스템SW 유지보수  2024.05 ~ 2025.04</li>
                    <li>롯데칠성음료 한도견본 검증시스템 구축 2024.04 ~ 2024.08</li>
                    <li>롯데이노베이트(주) 데이터 마켓플레이스 플랫폼 구축(대화형 BI) 2024.04 ~ 2024.07</li>
                    <li>롯데이노베이트(주) 하이마트 고객생애주기 모델개발 I/F 2024.04 ~ 2024.05</li>
                    <li>롯데정보통신 하이마트 콜센터 개선용 TA PKG 개발 2024.02 ~ 2024.06 </li>
                    <li>롯데정보통신 마트 AI/ML 수요예측 구축 2024.01.15 ~ 2024.03.14 </li>
                    <li>현대모비스 2024년 시스템 운영 - 필드클레임 분류모델 2024.01 ~ 2024.12</li>
                    </ul>
                  </div>
                </div>
              </v-col>
              <v-col lg="6" md="6" cols="12" style="padding-bottom:20px;">
                <div class="inner">
                  <div class="section-title">
                    <span class="subtitle">Year of</span>
                    <h2 class="heading-title">2023</h2>
                  </div>
                  <div class="mt--20">
                    <ul class="history-area">
                    <li>세종문화회관 데이터 관리체계 수립을 위한 컨설팅 용역 2023.11 ~ 2023.12</li>
                    <li>롯데정보통신 STT/TA 서비스 기능 개선  2023.10 ~ 2023.12</li>
                    <li>롯데웰푸드 고객센터 시스템 ASP 구축 2023.10 ~ 2023.12</li>
                    <li>현대모비스 23년 운영 시스템 서비스부품 차세대DW 운영2차 데이터레이크 2023.10 ~ 2023.12</li>
                    <li>현대모비스 서비스부품 차세대DW 구축 운영 안정화 2023.08 ~ 2023.09</li>
                    <li>롯데정보통신 데이터 마켓플레이스 플랫폼 구축_MLOps 파이프라인 2023.07~2024.01</li>
                    <li>롯데칠성음료 데이터레이크 및 POS 시스템 아웃소싱 운영 2023.07 ~ 2024.06</li>
                    <li>현대모비스 23년 운영 시스템 서비스부품 차세대DW 운영 데이터레이크 2023.06 ~ 2023.08</li>
                    <li>롯데칠성음료 SOP고도화  2023.03 ~ 2023.10</li>
                    <li>현대모비스 서비스부품 차세대 DW 구축  2023.02 ~ 2023.07</li>
                    <li>현대모비스 23년 운영시스템 필드클레임 분류모델  2023.02 ~ 2023.12 </li>
                    </ul>
                  </div>
                </div>
              </v-col>
              <v-col lg="6" md="6" cols="12" style="padding-bottom:20px;">
                <div class="inner">
                  <div class="section-title">
                    <span class="subtitle">Year of</span>
                    <h2 class="heading-title">2022</h2>
                  </div>
                  <div class="mt--20">
                    <ul class="history-area">
                    <li>롯데정보통신 롯데칠성 POS시스템 고도화 및 데이터 추가 프로젝트 2022.12 ~ 2023.05</li>
                    <li>롯데정보통신 데이터ECO 플랫폼 구축 2022.08 ~ 2023.01 </li>
                    <li>현대모비스 모비스 서비스부품 차세대DW 선행 구축 2022.07 ~ 2022.11</li>
                    <li>롯데칠성음료 롯데칠성 스마트 POS 시스템 구축 2022.06 ~ 2022.09</li>
                    <li>롯데칠성음료 S&OP 수요예측 모델 고도화 2022.03 ~ 2022.06</li>
                    </ul>
                  </div>
                </div>
              </v-col>
              <v-col lg="6" md="6" cols="12" style="padding-bottom:20px;">
                <div class="inner">
                  <div class="section-title">
                    <span class="subtitle">Year of</span>
                    <h2 class="heading-title">2021</h2>
                  </div>
                  <div class="mt--20">
                    <ul> 
                      <li>현대기아차그룹 파이롯트 신차개발 문제점 분석 모델 고도화</li>
                      <li>현대모비스 RO (Repair Order) 분류 모델 및 서비스 시스템</li> 
                      <li>롯데홈쇼핑 추천플랫폼 유지보수 (빅데이터 인프라 및 ETL 파트)</li>
                      <li>롯데칠성 데이터 파이프라인 및 MLOps 플랫폼 구축</li>
                      <li>롯데정보통신 텍스트분석 엔진 개발 및 상담품질 관리시스템 구축</li>
                      <li>관세청 우범화물 분류 모델 개발</li>
                      <li>EZPMP 추천 시스템 프로세스 자동화 및 서비스 솔루션 </li>
                    </ul>
                  </div>
                </div>
              </v-col>
              <v-col lg="6" md="6" cols="12" style="padding-bottom:20px;">
                <div class="inner">
                  <div class="section-title">
                    <span class="subtitle">Year of</span>
                    <h2 class="heading-title">2020</h2>
                  </div>
                  <div class="mt--20">
                    <ul class="history-area">
                    <li>롯데이커머스 통합EC 추천플랫폼 구축</li>
                    <li>코트라 빠른FAQ서비스 유지보수</li>
                    <li>롯데이커머스  쇼핑 어드바이져 운영</li>
                    <li>롯데홈쇼핑 추천플랫폼 구축 (빅데이터 인프라 및 ETL 파트)</li>
                    <li>현대기아차그룹 지능형 정비 추천 고도화</li>
                    </ul>
                  </div>
                </div>
              </v-col>

              <v-col lg="6" md="6" cols="12" style="padding-bottom:20px;">
                <div class="inner">
                  <div class="section-title">
                    <span class="subtitle">Year of</span>
                    <h2 class="heading-title">2019</h2>
                  </div>
                  <div class="mt--20">
                    <ul class="history-area">
                    <li>현대기아차그룹 파이롯트 지능형 신차개발 문제점 분석 모델 개발</li>
                    <li>현대기아차그룹 지능형 정비추천 서비스 글로벌 확대</li>
                    <li>현대건설 하도급 입찰 참여업체 추천 알고리즘 개발</li>
                    <li>현대건설 빅데이터 분석 PoC (CS하자원인 도출)</li>
                    <li>롯데칠성음료 데이터 레이크 구축</li>
                    <li>롯데이커머스 추천엔진 플랫폼 개발</li>
                    <li>㈜아이노리랩 IT 인프라시스템 위탁 운영 및 관리</li>
                    <li>코트라 빠른FAQ서비스 유지보수</li>
                    <li>롯데이커머스 쇼핑 어드바이져 운영</li>
                    <li>서울시 산학연 협력사업 중 ‘혁신기술 공공테스트베드 제공사업<br />
                      (IoT 통합시스템 개발 및 서비스 운영을 통한 사용자 편익 제공과 공공   데이터 자원 활용)</li>
                    </ul>
                  </div>
                </div>
              </v-col>


              <v-col lg="6" md="6" cols="12" style="padding-bottom:20px;">
                <div class="inner">
                  <div class="section-title">
                    <span class="subtitle">Year of</span>
                    <h2 class="heading-title">2018</h2>
                  </div>
                  <div class="mt--20">
                    <ul class="history-area">
                    <li>신한은행 인공지능기반 자본시장 경쟁력강화 분석모델 관리서비스</li>
                    <li>롯데 지능형쇼핑어드바이저 운영</li>
                    <li>한라/만도 실시간 결함 판별용 딥러닝 분석 시스템 개발</li>
                    <li>대한무역진흥공사 (KOTRA) 지능형 빠른상담 (FAQ) 서비스</li>
                    </ul>
                  </div>
                </div>
              </v-col>


              <v-col lg="6" md="6" cols="12" style="padding-bottom:20px;">
                <div class="inner">
                  <div class="section-title">
                    <span class="subtitle">Year of</span>
                    <h2 class="heading-title">2017</h2>
                  </div>
                  <div class="mt--20">
                    <ul class="history-area">
                    <li>현대기아차그룹 지능형정비추천서비스</li>
                    <li>10턴즈 고객관리시스템 개발</li>
                    <li>IBM-롯데 인공지능 쇼핑 어드바이저</li>
                    <li>현대기아차그룹 소셜미디어 분석
          </li>
                    </ul>
                  </div>
                </div>
              </v-col>


              <v-col lg="6" md="6" cols="12" style="padding-bottom:20px;">
                <div class="inner">
                  <div class="section-title">
                    <span class="subtitle">Year of</span>
                    <h2 class="heading-title">2016 ~ 2015</h2>
                  </div>
                  <div class="mt--20">
                    <ul class="history-area">
                    <li>현대자동차 그룹 소셜미디어 분석 시스템 개발</li>
                    <li>한국공간정보 시스템 검색엔진, POI 자동 업데이트 및 REST API 서비스 개발</li>
                    <li>SKTelecom IoT 분석 플랫폼 개발 (Thing Plug Analytics; MWC 2016 전시)</li>
                    <li>KB국민카드 빅데이터 활용 전략 컨설팅</li>
                    <li>ETRI Exo Brain: 빅데이터기반 의료정보 자연어처리 및 BI 시스템 개발</li>
                    <li>강원도 응급의료거버넌스 컨설팅 및 프로토타입 개발</li>
                    <li>LGCNS Smart SMA Version 3 개발 및 운영, ODPia 시스템 설계</li>
                    </ul>
                  </div>
                </div>
              </v-col>


              <v-col lg="6" md="6" cols="12" style="padding-bottom:20px;">
                <div class="inner">
                  <div class="section-title">
                    <span class="subtitle">Year of</span>
                    <h2 class="heading-title">2014</h2>
                  </div>
                  <div class="mt--20">
                    <ul class="history-area">
                    <li>DLab 데이터 수집, 모니터링, 컨텐츠 관리 모듈 개발 (Data Analytics Platform)</li>
                    <li>LGCNS SmartSMA Version 2 개발 완료</li>
                    <li>한국은행 금융기관 자금 결제 모니터링, 네트워크 분석 및 시각화</li>
                    <li>LG생명과학 CRM (SFA & 환자관리 모듈)</li>
                    </ul>
                  </div>
                </div>
              </v-col>

              <v-col lg="6" md="6" cols="12" style="padding-bottom:20px;">
                <div class="inner">
                  <div class="section-title">
                    <span class="subtitle">Year of</span>
                    <h2 class="heading-title">2013 ~ 2011</h2>
                  </div>
                  <div class="mt--20">
                    <ul class="history-area">
                    <li>LG전자 한국 마케팅 소셜미디어 분석 서비스</li>
                    <li>신한은행 Social Media 크로울링 및 데이터 분석 서비스</li>
                    <li>LG전자 중국법인 소셜미디어 분석 서비스</li>
                    <li>LGCNS Advanced Analytics Center Social Media Analysis 플랫폼 공동 개발</li>
                    <li>LG전자 Global Marketing Portal 개선 프로젝트</li>
                    <li>서울시 3D 지도 서비스 검색엔진 납품</li>
                    <li>스마트그리드 보급 시범사업 빅데이터 부문 컨설팅 (기존 DB  빅데이터 전환)</li>
                    <li>Sanofi-Aventis CENOVIS CRM Dashboard & 고객 통합 프로젝트</li> 
                    <li>LG전자 AE BRM 개선 및 2011년 신규 구축 고객 마스터</li>
                    <li>LG하우시스 CRM / PMS 시스템 컨설팅</li>
                    </ul>
                  </div>
                </div>
              </v-col>

              <v-col lg="6" md="6" cols="12" style="padding-bottom:20px;">
                <div class="inner">
                  <div class="section-title">
                    <span class="subtitle">Year of</span>
                    <h2 class="heading-title">2010 ~ 2008</h2>
                  </div>
                  <div class="mt--20">
                    <ul class="history-area">
                    <li>LG전자 HE CRM / PRM 구축 컨설팅</li>
                    <li>회계법인 프로젝트 관리시스템 개발, 재개발지구 소재지 관리프로그램 개발</li>
                    <li>원격발전 모니터링 시스템 개발 및 서비스 개시 </li>
                    <li>대한지적공사 CRM 시스템 컨설팅 및 구축</li>
                    <li>통로이미지스톡 이미지라이브러리 관리 및 검색 컨설팅</li>
                    <li>브로드밴드쇼핑네트웍스㈜ BI 시스템 컨설팅</li>
                    </ul>
                  </div>
                </div>
              </v-col>


              <v-col lg="6" md="6" cols="12" style="padding-bottom:20px;">
                <div class="inner">
                  <div class="section-title">
                    <span class="subtitle">Year of</span>
                    <h2 class="heading-title">2007</h2>
                  </div>
                  <div class="mt--20">
                    <ul class="history-area">
                    <li>㈜광개토연구소 특허정보 시스템 (검색 및 분석) 컨설팅 및 구축</li>
                    <li>회계법인리안, ㈜에이록스 PMS 구축</li>
                    <li>㈜데이터엔지니어스랩 설립</li>
                    </ul>
                  </div>
                </div>
              </v-col>

            </v-row>
          </template>
        </div>
      </div>
    </div>
    <!-- Start About Area  -->

    
    
    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
  import Header from "../../components/header/Header";
  import Portfolio from "../../components/portfolio/Portfolio";
  import ServiceTwo from "../../components/service/ServiceTwo";
  import AboutThree from "../../components/about/AboutTwo";
  import CounterOne from "../../components/counter/CounterOne";
  import Testimonial from "../../components/testimonial/Testimonial";
  import Blog from "../../components/blog/Blog";
  import Brand from "../../components/brand/Brand";
  import Footer from "../../components/footer/Footer";

  export default {
    components: {
      Header,
      Portfolio,
      ServiceTwo,
      AboutThree,
      CounterOne,
      Testimonial,
      Blog,
      Brand,
      Footer,
    },
    data() {
      return {
        items: [
          {
            thumb: require("../../assets/images/blog/bl-big-02.jpg"),
            src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
          },
        ],
        index: null,
        logo: require("../../assets/images/logo/logo.png"),
      };
    },
  };
</script>

<style lang="scss">
  @media only screen and (min-width: 1264px) and (max-width: 1903px) {
    .slider-video-bg.slide.slide-style-2 {
      padding-top: 160px;
      padding-bottom: 260px;
      min-height: auto;
    }
  }
</style>
