<template>
  <!-- Start Service Area  -->
  <v-row class="service-main-wrapper">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="6"
      sm="6"
      cols="12"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="text-left service service__style--2">
        <router-link :to="service.to">
          <div class="service">
            <div class="content">
              <h2 class="heading-title">{{ service.title }}</h2>
              <p align="justify">
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
  <!-- Start Service Area  -->
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
          {
            icon: "cast",
            title: "DBAP",
            to: "/DBAP",
            desc: `모든 형태의 데이터 카탈로그를 자유롭게 생성하고 결합할 수 있습니다. 분석 노트북을 통해 데이터 전문가 없이도 분석을 자유롭게 수행 할 수 있습니다. 이를 통해 사용자는 데이터를 보다 효과적으로 활용할 수 있습니다.`,
          },
          {
            icon: "monitor",
            title: "LoadUp",
            to: "/LoadUp",
            desc: `뉴스, 블로그, 카페 그리고 소셜 미디어의 방대한 데이터를 수집, 분석, 시각화하는데 특화된 혁신적인 빅데이터 분석 프레임워크입니다. 이는 사용자의 경험 개선과 데이터 기반의 명확한 비즈니스 결정을 가능하게 합니다.`,
          },
          {
            icon: "layers",
            title: "Donto",
            to: "/Donto",
            desc: `텍스트 분석에 필요한 사용자 사전, 개체명 인식 사전, 형태소 분석 기능을 포함하는 Open API 서비스를 제공합니다. 해당 서비스는 빅데이터 분석과 자연어 처리 작업을 간소화하며 효율적인 서비스를 제공할 수 있습니다.`,
          },
          
          {
            icon: "users",
            title: "DStocks",
            to: "/DStocks",
            desc: `다양한 주식 시장 데이터를 활용하여 Python과 Fast API를 기반으로 한 고급 머신러닝 기법과 복합 비즈니스 로직을 통합한 분석을 수행합니다. 이 서비스는 시장 동향, 투자 기회 등에 대한 인사이트를 제공할 수 있습니다.`,
          },
          {
            icon: "mail",
            title: "DCrawler",
            to: "/DCrawler",
            desc: `다양한 웹사이트 크롤링과 SSL/TLS 보안을 강화를 제공하는 이 도구는 Scrapy, Nutch, Open API의 단점을 개선하였습니다. 복잡한 데이터 소스에서 사용자가 원하는 정보만을 쉽게 추출하여 여러 형태로 제공 가능합니다.`,
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
